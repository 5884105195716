<template>
    <div>
        <div class='main-loader' v-if='isLoading'></div>
        <section class="pricing">
            <b-container>
                <!-- User Account Overview Section -->
                <b-card class="mb-4 user-details-card" v-if="userDetails">
                    <b-row>
                        <b-col md="3" class="text-center border-right px-4 py-3">
                            <b-avatar
                                :src="userDetails.photoURL"
                                :text="getUserInitials"
                                size="100px"
                            />
                            <h4 class="mt-3">{{ userDetails.userName }}</h4>
                            <b-badge :variant="getAccountStatusVariant">{{ userDetails.status }}</b-badge>
                        </b-col>
                        <b-col md="9" class="px-4 py-3">
                            <b-row>
                                <b-col md="6" lg="4" class="mb-3">
                                    <div class="stat-card p-4 bg-light-primary rounded">
                                        <h6 class="mb-2">Current Plan</h6>
                                        <h4 class="mb-0 text-primary text-capitalize">{{ userDetails.plan }}</h4>
                                    </div>
                                </b-col>
                                <b-col md="6" lg="4" class="mb-3">
                                    <div class="stat-card p-4 bg-light-success rounded">
                                        <h6 class="mb-2">Total Executions</h6>
                                        <h4 class="mb-0 text-success">{{ userDetails.totalExecutionCount }}</h4>
                                    </div>
                                </b-col>
                                <b-col md="6" lg="4" class="mb-3">
                                    <div class="stat-card p-4 bg-light-info rounded">
                                        <h6 class="mb-2">Account Type</h6>
                                        <h4 class="mb-0 text-info text-capitalize">{{ userDetails.account }}</h4>
                                    </div>
                                </b-col>
                            </b-row>
                            
                            <!-- Usage Progress -->
                            <!-- <div class="mt-4 mb-4">
                                <h6 class="mb-2">Monthly Usage</h6>
                                <b-progress :max="100" height="2rem" class="mb-2">
                                    <b-progress-bar :value="getUsagePercentage" :variant="getUsageVariant">
                                        {{ getUsagePercentage }}% Used
                                    </b-progress-bar>
                                </b-progress>
                            </div> -->

                            <!-- Account Details -->
                            <!-- <div class="account-details mt-4">
                                <b-row>
                                    <b-col md="6" class="mb-3">
                                        <small class="text-muted d-block mb-1">Email</small>
                                        <p class="mb-0">{{ userDetails.email }}</p>
                                    </b-col>
                                    <b-col md="6" class="mb-3">
                                        <small class="text-muted d-block mb-1">Location</small>
                                        <p class="mb-0">{{ userDetails.city }}, {{ userDetails.country }}</p>
                                    </b-col>
                                </b-row>
                            </div> -->
                        </b-col>
                    </b-row>
                </b-card>

                <!-- Subscription Plans Section -->
                <div class="mb-4">
                    <div class="text-center">
                        <h1>Subscription Plans</h1>
                        <div class="divider-red"></div>
                        <p class="mb-2">Choose a plan based on your requirements, free onboarding offered for all paid plans</p>
                    </div>
                </div>
                
                <b-row class="pricing-card justify-content-center match-height">
                    <b-col md="6" lg="4" v-for="(item, index) in membershipDetails" :key="index">
                        <b-card class="text-center">
                            <b-img v-if='item.membership_type_image_url==null' :src="require('@/assets/images/illustration/Pot2.svg')" class="mb-2 membership-default-image" alt="basic svg img" />
                            <b-img v-else :src="item.membership_type_image_url" class="mb-2 membership-default-image" />
                            <h2 class="font-weight-bold text-uppercase text-dark">{{item.membership_type_name}}</h2>
                            <div class="up-pricing">
                                <template v-if="item.membership_type_id === 600">
                                    <strong class="up-price text-uppercase text-primary">$0.10</strong>
                                    <span class="up-month small">per Run</span>
                                </template>
                                <template v-else>
                                    <strong class="up-price text-uppercase text-primary">${{item.membership_price}}</strong>
                                    <span class="up-month small">per Month</span>
                                </template>
                            </div>

                            <b-list-group class="list-group-circle text-left">
                                <b-list-group-item v-for="(benefit, bIndex) in item.benefit_lists" :key="bIndex">
                                    {{benefit.benefit_description}}
                                </b-list-group-item>
                            </b-list-group>

                            <b-button 
                                block 
                                :variant="getCurrentPlanVariant(item)" 
                                class="mt-2"
                                :disabled="isCurrentPlan(item)"
                                @click='purchaseMembership(item)'
                            >
                                {{ getPlanButtonText(item) }}
                            </b-button>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
import {
    BFormCheckbox,
    BContainer,
    BRow,
    BCol,
    BCard,
    BImg,
    BListGroup,
    BListGroupItem,
    BButton,
    BBadge,
    BAvatar,
    BProgress,
    BProgressBar
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BFormCheckbox,
        BContainer,
        BButton,
        BListGroup,
        BListGroupItem,
        BRow,
        BCol,
        BCard,
        BBadge,
        BImg,
        BAvatar,
        BProgress,
        BProgressBar
    },
    directives: {
        Ripple,
    },
    created() {
        this.initializeUserDetails();
        this.initializeMembershipDetails();
    },
    data() {
        return {
            isLoading: false,
            userDetails: null,
            membershipDetails: [],
            // Usage limits based on plan
            planLimits: {
                starter: 25,
                professional: 100,
                business: 750
            }
        }
    },
    computed: {
        getUserInitials() {
            if (!this.userDetails?.userName) return '';
            return this.userDetails.userName
                .split(' ')
                .map(name => name[0])
                .join('')
                .toUpperCase();
        },
        getAccountStatusVariant() {
            const status = this.userDetails?.status;
            const variants = {
                active: 'success',
                suspended: 'danger',
                pending: 'warning'
            };
            return variants[status] || 'secondary';
        },
        getUsagePercentage() {
            if (!this.userDetails) return 0;
            const limit = this.planLimits[this.userDetails.plan] || 200;
            return Math.min(Math.round((this.userDetails.totalExecutionCount / limit) * 100), 100);
        },
        getUsageVariant() {
            const usage = this.getUsagePercentage;
            if (usage >= 90) return 'danger';
            if (usage >= 75) return 'warning';
            return 'primary';
        }
    },
    methods: {
        initializeUserDetails() {
            const userData = localStorage.getItem("userData");
            if (userData) {
                this.userDetails = JSON.parse(userData);
            }
        },
        initializeMembershipDetails() {
            this.membershipDetails = [
                {
                    membership_type_id: 'starter',
                    membership_type_name: "Creator",
                    membership_price: "0",
                    membership_type_image_url: require('@/assets/images/illustration/Pot1.svg'),
                    benefit_lists: [
                        { benefit_description: "25 runs (4 product images/run) per month" },
                        { benefit_description: "Unlimited text content creation" },
                        { benefit_description: "1 product/agent training" },
                        { benefit_description: "Product video creation (Coming soon)" },
                        { benefit_description: "One-click social media posting (Coming soon)" },
                        { benefit_description: "Basic email support" },
                        { benefit_description: "Standard features" }
                    ]
                },
                {
                    membership_type_id: 'professional',
                    membership_type_name: "Creator Pro",
                    membership_price: "20",
                    membership_type_image_url: require('@/assets/images/illustration/Pot2.svg'),
                    benefit_lists: [
                        { benefit_description: "125 runs (4 product images/run) per month" },
                        { benefit_description: "Unlimited text content creation" },
                        { benefit_description: "10 product/agent trainings" },
                        { benefit_description: "Product video creation (Coming soon)" },
                        { benefit_description: "One-click social media posting (Coming soon)" },
                        { benefit_description: "Dedicated onboarding and agent training support" },
                        { benefit_description: "Priority processing" },
                        { benefit_description: "Advanced features" },
                        { benefit_description: "Early access to new AI models" }
                    ]
                },
                {
                    membership_type_id: 'business',
                    membership_type_name: "Creator business",
                    membership_price: "100",
                    membership_type_image_url: require('@/assets/images/illustration/Pot3.svg'),
                    benefit_lists: [
                        { benefit_description: "500 runs (4 product images/run) per month" },
                        { benefit_description: "Unlimited text content creation" },
                        { benefit_description: "50 product/agent trainings" },
                        { benefit_description: "Product video creation (Coming soon)" },
                        { benefit_description: "One-click social media posting (Coming soon)" },
                        { benefit_description: "Dedicated onboarding and agent training support" },
                        { benefit_description: "Priority processing" },
                        { benefit_description: "Advanced features" },
                        { benefit_description: "Early access to new AI models" },
                        { benefit_description: "Custom solutions" }
                    ]
                }
            ];
        },
        isCurrentPlan(plan) {
            return this.userDetails?.plan === plan.membership_type_id;
        },
        getCurrentPlanVariant(plan) {
            return this.isCurrentPlan(plan) ? 'success' : 'primary';
        },
        getPlanButtonText(plan) {
            return this.isCurrentPlan(plan) ? 'Current Plan' : 'Upgrade Now';
        },
        purchaseMembership(plan) {
            if (!this.userDetails) {
                this.$bvModal.show("modal-login");
                return;
            }
            
            // If it's current plan, do nothing
            if (this.isCurrentPlan(plan)) return;

            // Navigate to checkout with plan details
            // this.$router.push({
            //     name: "membership-checkout",
            //     query: {
            //         plan: plan.membership_type_id,
            //         customerId: this.userDetails.stripeCustomerId
            //     }
            // });
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';

.divider-red {
    height: 3px;
    width: 100px;
    background-color: #DC123E;
    margin: 15px auto;  // Centers the divider and adds spacing
}

.user-details-card {
    margin: 0rem 0;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    
    .stat-card {
        transition: all 0.3s ease;
        &:hover {
            transform: translateY(-5px);
        }
    }

    .border-right {
        border-right: 1px solid #ebe9f1;
    }

    .account-details {
        background-color: #f8f8f8;
        border-radius: 0.5rem;
        padding: 1.5rem;

        small {
            font-size: 0.875rem;
        }

        p {
            font-size: 1rem;
            font-weight: 500;
        }
    }
}

.bg-light-primary {
    background-color: rgba(115, 103, 240, 0.12) !important;
}

.bg-light-success {
    background-color: rgba(40, 199, 111, 0.12) !important;
}

.bg-light-info {
    background-color: rgba(0, 207, 232, 0.12) !important;
}

.pricing-card {
    margin-top: 2rem;
    
    .membership-default-image {
        height: 135px;
    }

    .membership-image {
        height: 256px;
        width: 100%;
        object-fit: cover;
    }
}

// Add some responsive padding adjustments
@media (max-width: 768px) {
    .user-details-card {
        .border-right {
            border-right: none;
            border-bottom: 1px solid #ebe9f1;
            margin-bottom: 1.5rem;
            padding-bottom: 1.5rem;
        }
    }
}
</style>